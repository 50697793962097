.radio {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    min-height: 16px;
    border-radius: 50px;
    margin-right: 10px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.dot{
    width: 6px;
    height: 6px;
    border-radius: 50px;
    transition: all .2s ease-in-out;
}
