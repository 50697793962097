.emprow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto;
    padding-right: 20px;
}

.emprow_inputs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
}

.emprow_input_block{
    width: 48%;
}

.last_activity, .activated_change_date{
    color: var(--hardGray);
}

.last_activity{
    width: 125px;
}

.activated_change_date{
    width: 165px;
}

.utils_panel{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 14.8%;
}
