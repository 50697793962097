.select_container{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
}

.select{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 5px 0 20px;
  border-right: 1px solid rgba(25, 52, 77, .15);
  background-color: transparent;
}

.select img{
  max-height: 70%;
  margin-right: 10px;
}

.select input{
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
}

.select input:focus{
  outline: none;
}

.select .clear_container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.select .clear{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  margin-bottom: 2px;
  width: 15px;
  height: 15px;
  font-size: 23px;
  cursor: pointer;
  user-select: none;
  transition: all .2s ease-in-out;
}

.select .clear:hover{
  color: red;
  border-color: red;
}

.item_list{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  max-height: 0px;
  left: -5%;
  width: 110%;
  overflow-y: scroll;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 46px rgba(32, 144, 251, 0.38);
  transition: max-height .2s ease-in-out;
  z-index: 1002;
}

.list_active{
  max-height: 300px;
}

.calendar_container {
  margin: 20px 20px 40px 20px;
  width: 100%;
}

.years_slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 38px;
  border-radius: 1000px;
  background-color: var(--lightBlue);
}

.years_slider_arrow {
  color: rgba(25, 52, 77, 0.46);
  font-size: 25px;
  user-select: none;
}

.selected_year {
  font-size: 17px;
  font-weight: 700;
}

.monthes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.month {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 35px;
  border-radius: 41px;
  color: #263238;
  letter-spacing: 0.25;
  font-size: 14;
  font-weight: 300;
  cursor: pointer;
  user-select: none;
}

.month_selected {
  color: white;
  background-color: var(--hardBlue);
}

.ok_btn {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 75px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 25px;
  border-radius: 1000px;
  background-color: var(--hardBlue);
  color: white;
  transition: all .2s ease-in-out;
  cursor: pointer;
  user-select: none;
}

.ok_btn:hover {
  background-color: var(--hardBlueHovered);
}
