.tabs{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;
    height: 60px;
}

.tab{
    height: 100%;
    width: 13.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(25, 52, 77, .17);
    border-radius: 100px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.tab_switch {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 4px);
    height: calc(100% - 6px);
    border-radius: 1000px;
    box-shadow: 0px 4px 46px rgba(50, 130, 207, 0.06);
}

.tab_logo{
    margin-right: 10px;
}
