.input_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    height: 60px;
    background: #FFF;
    box-shadow: 0px 4px 40px rgba(50, 130, 207, 0.06);
}

.input_container input{
    height: 100%;
    width: 95%;
    font-size: 18px;
    border: none;
}

.input_container input:focus{
    outline: none;
}

.icon_container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
}

.icon_container img{
    width: 20px;
}
