.confirm_container{
    position: relative;
    width: 30vw;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    z-index: 6;
}

.confirm_close{
    position: absolute;
    top: -6px;
    right: 5px;
    font-size: 40px;
    cursor: pointer;
    user-select: none;
}

.confirm_message{
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
}

.confirm_buttons_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.cancel_btn, .submit_btn{
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
}

.cancel_btn{
    background-color: #999999;
}

.submit_btn{
    background-color: var(--hardBlue);
}

.cancel_btn:hover, .submit_btn:hover{
    opacity: .8;
}
