.card {
  background-color: transparent;
  outline: thin solid;
  outline-color: rgba(74, 100, 123, .23);
  border-radius: 100px;
}

.cell_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.color_cell {
  width: 29px;
  height: 29px;
  border-radius: 50%;
}

.clickable_icon {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: var(--lightBlue);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: all .2s ease-in-out;
}

.clickable_icon:hover {
  background-color: var(--lightBlueHovered);
}
