.info_icon{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--lightBlue);
  color: var(--hardBlue);
  font-weight: 600;
  cursor: pointer;
  user-select: none;
}
