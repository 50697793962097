.heading {
  margin-top: 64px;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}

.add_btn {
  margin: 25px 0;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: var(--lightBlue);
  color: var(--hardBlue);
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  transition: background-color .2s ease-in-out;
}

.add_btn:hover {
  background-color: var(--lightBlueHovered);
}

.datasets_table {
  border-spacing: 0 10px;
}

.datasets_table th {
  font-size: 13px;
  font-weight: 400;
}
