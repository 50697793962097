.pagination_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    width: 510px;
}

.first_page, .previous_page, .next_page, .last_page{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 20px;
    font-weight: 500;
    background: var(--lightBlue);
    border-radius: 1000px;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease-in-out;
}

.first_page:hover, .previous_page:hover, .next_page:hover, .last_page:hover{
    background-color: var(--hardBlue);
    color: white;
}

.disabled_btn, .disabled_btn:hover{
    background-color: transparent;
    color: gray;
}

.pagination_numbers{
    width: 300px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    background: var(--lightBlue);
    border-radius: 1000px;
}

.pagination_item{
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    cursor: pointer;
    user-select: none;
}

.pagination_item_link{
    text-decoration: none;
    color: var(--hardBlue);
}

.pagination_item_active{
    background-color: var(--hardBlue);
}

.pagination_item_active a{
    color: white;
}