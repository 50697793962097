section{
  display: flex;
}

header{
  position: fixed;
  top: 0;
  overflow: hidden;
  width: 200px;
  height: 100vh;
  border-right: 1px solid rgba(25, 52, 77, .1);
  z-index: 10000000;
  background: #fff;
  transition: all .2s ease-in-out;
}

main{
  width: 100%;
  height: 100vh;
}
