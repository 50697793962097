.company_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;
    padding: 5px 20px;
    border-radius: 5px;
    min-height: 65px;
    background: white;
    cursor: pointer;
    user-select: none;
}

.name_section,
.icons_section {
    display: flex;
    align-items: center;
}

.name_section {
    width: 25%;
}

.users_count {
    display: flex;
    align-items: center;
    width: 24%;
    min-width: 130px;
    color: var(--hardGray);
    font-weight: 400;
}

.users_count_num {
    font-weight: 500;
    color: #19344D;
}

.activated_change_date, .last_user_activity {
    color: var(--hardGray);
    font-weight: 400;
}

.activated_change_date{
    width: 165px;
}

.last_user_activity{
    width: 120px;
}

.icons_section {
    width: 15%;
    justify-content: space-between;
}

.arrow {
    width: 10px;
    height: 10px;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    transition: transform .2s ease-in-out;
}

.card_content{
    overflow: hidden;
    transition: height .2s ease-in-out;
}

.content_add_user{
    display: flex;
    align-items: center;
    margin: 15px 0 0 20px;
    font-size: 15px;
    font-weight: 600;
}

.content_plus_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    padding-top: 3px;
    font-size: 25px;
    font-weight: 400;
    color: var(--hardBlue);
    background-color: var(--lightBlue);
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
    transition: background-color .2s ease-in-out;
}

.content_plus_icon:hover{
    background-color: var(--lightBlueHovered);
}
