.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--lightBlue);
    transition: 0.2s;

    border-radius: 26px;
}

.slider::before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.2s;

    border-radius: 50%;
}

input:checked+.slider {
    background-color: var(--hardBlue);
}

input:checked+.slider::before {
    transform: translateX(17px);
}
