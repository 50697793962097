.language_container, .language_container_item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	max-width: 290px;
	width: 100%;
	height: 50px;
	transition: all .2s ease-in-out;
	user-select: none;
}

.language_container {
	background-color: var(--lightBlue);
}

.language_container_wrapper {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.language_container_wrapper:hover {
	/* background-color: var(--lightBlueHovered); */
	cursor: pointer;
}

.language_variants {
	background-color: var(--lightBlue);
	transition: all .2s ease-in-out;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.arrow_container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15px;
}

.arrow {
	width: 8px;
	height: 8px;
	margin-bottom: 6px;
	border-bottom: 1px solid black;
	border-right: 1px solid black;
	transition: all .2s ease-in-out;
}

.language_name {
	flex: 1;
	margin-left: 10px;
}

.copy_icon {
	border-radius: 50%;
	padding: 5px;
	cursor: pointer;
	display: flex;
}

.copy_icon:hover {
	background-color: var(--lightBlueHovered);
}