.passive_navlink, .active_navlink{
    margin: 25px;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease-in-out;
}

.passive_navlink{
    font-size: 14px;
}

.active_navlink{
    color: var(--hardBlue);
}
