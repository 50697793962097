.loader_page{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loader_container{
    width: 500px;
    height: 370px;
}

.loader_container_img_container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    background-color: #cce6ff;
    border-radius: 50%;
}

.loader_container_img{
    margin-top: 15px;
    width: 85%;
}

.loader_text{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
}

.loader_text p{
    margin: 0;
}

.loader_text, .loader_text p{
    color: var(--hardBlue);
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 8px;
}

.loader_text p:nth-child(1){
    animation: first_point 1s ease-in-out infinite;
}

.loader_text p:nth-child(2){
    animation: second_point 1s ease-in-out infinite;
}

.loader_text p:nth-child(3){
    animation: third_point 1s ease-in-out infinite;
}

.loader_icons{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 70px;
    margin: 0 auto;
    overflow: hidden;
}

.loader_wave_icon{ animation: icon_anim 5s ease-in-out infinite; }
.loader_train_icon{ animation: icon_anim 5s ease-in-out infinite .1s; }
.loader_cross_icon{ animation: icon_anim 5s ease-in-out infinite .2s; }
.loader_auto_icon{ animation: icon_anim 5s ease-in-out infinite .3s; }
.loader_given_icon{ animation: icon_anim 5s ease-in-out infinite .4s; }

@keyframes first_point{
    0%{
        color: white;
    }
    25%{
        color: var(--hardBlue);
    }
    50%{
        color: var(--hardBlue);
    }
    75%{
        color: var(--hardBlue);
    }
    100%{
        color: white;
    }
}

@keyframes second_point{
    0%{
        color: white;
    }
    25%{
        color: white;
    }
    50%{
        color: var(--hardBlue);
    }
    75%{
        color: var(--hardBlue);
    }
    100%{
        color: white;
    }
}

@keyframes third_point {
    0%{
        color: white;
    }
    25%{
        color: white;
    }
    50%{
        color: white;
    }
    75%{
        color: var(--hardBlue);
    }
    100%{
        color: white;
    }
}

@keyframes icon_anim {
    from{
        margin-top: 150px;
    }
    5%{
        margin-bottom: 60px;
    }
    10%{
        margin: 0;
    }
    40%{
        margin: 0;
    }
    90%{
        margin: 0;
    }
    95%{
        margin-top: 150px;
    }
    to{
        margin-top: 150px;
    }
}
