.select_container{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
}

.select{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 5px 0 20px;
    border-right: 1px solid rgba(25, 52, 77, .15);
    background-color: transparent;
}

.select img{
    max-height: 70%;
    margin-right: 10px;
}

.select input{
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    font-size: 14px;
}

.select input:focus{
    outline: none;
}

.select .clear_container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
}

.select .clear{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
    margin-bottom: 2px;
    width: 15px;
    height: 15px;
    font-size: 23px;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease-in-out;
}

.select .clear:hover{
    color: red;
    border-color: red;
}

.item_list{
    position: absolute;
    max-height: 0px;
    width: 100%;
    overflow-y: scroll;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 4px 46px rgba(32, 144, 251, 0.38);
    transition: max-height .2s ease-in-out;
    z-index: 1002;
}

.item_list::-webkit-scrollbar{
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 3px;
}

.item_list::-webkit-scrollbar-thumb{
    background-color: var(--hardBlue);
}

.list_active{
    max-height: 300px;
}

.info_item{
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 50px;
}

.one_item{
    display: flex;
    align-items: center;
    margin: 25px;
    width: calc(100% - 50px);
    height: 25px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    transition: color .2s ease-in-out;
}

.one_item:hover{
    color: var(--hardBlue);
}

.one_obj_item{
    margin: 15px 0;
    width: calc(100% - 50px);
    font-size: 13px;
    cursor: pointer;
    user-select: none;
}

.city_name{
    transition: color .2s ease-in-out;
}

.one_obj_item:hover .city_name{
    color: var(--hardBlue);
}

.city_container{
    display: flex;
    align-items: baseline;
    font-weight: 500;
}

.flag_icon{
    width: 12px;
    height: 10px;
    margin-right: 5px;
}

.country_block {
    margin: 20px 30px;
}

.country_name {
    font-size: 13px;
    font-weight: 300;
    color: rgba(25, 52, 77, 0.46);
}
