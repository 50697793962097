.pdf_reader_container{
    position: relative;
    padding: 40px;
    width: 1200px;
    height: 90vh;
    margin: 0 auto;
    background-color: white;
    border-radius: 15px;
}

.pdf_reader_close{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15px;
    right: 10px;
    width: 20px;
    height: 20px;
    font-size: 50px;
    color: var(--hardBlue);
    cursor: pointer;
    user-select: none;
}

.pdf_reader_name{
    position: absolute;
    top: 10px;
    width: 80%;
    left: 10%;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: var(--hardBlue);
}
