.container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 5px 5px 5px 20px;
    border: 1px solid var(--lightGray);
    border-radius: 100px;
}

.container input{
    color: var(--hardGray);
    background-color: transparent;
    font-weight: 300;
    font-size: 15px;
    padding-right: 5px;
    border: none;
    width: 100%;
}

.container input:focus{
    outline: none;
}

.label{
    margin-right: 25px;
    font-weight: 500;
    color: var(--hardGray);
}
