.app_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.logo {
    width: 90px;
}

.text {
    font-size: 30px;
    color: #43A3FF;
}

.auth_container {
    width: 400px;
}

.go_back {
    position: absolute;
    left: 2%;
    top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #19344D;
    cursor: pointer;
}

.go_back div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    width: 40px;
    height: 40px;
    font-size: 40px;
    font-weight: 200;
}

.go_back div:nth-child(2) {
    font-weight: 600;
}

.forgot {
    cursor: pointer;
    color: var(--hardBlue);
    font-size: 18px;
    text-decoration: underline;
}

.inbutton button {
    width: 100%;
    margin-top: 20px;
    background: var(--hardBlue);
    color: white;
    height: 50px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
}

.inbutton button:hover {
    background-color: var(--hardBlueHovered);
}

.inbutton button:disabled {
    opacity: .7;
}

.auth_options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.language_changer_container{
    position: absolute;
    top: 25px;
    right: 30px;
}
