.trash_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    background: var(--lightBlue);
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease-in-out;
}

.trash_icon:hover{
    background: var(--lightBlueHovered);
}

.disabled, .disabled:hover{
    background: var(--lightGray);
}

.trash_icon img {
    display: block;
    margin-right: 1px;
    width: 15px;
}
