.container {
  padding: 60px;
  width: 790px;
  min-height: 727px;
  border-radius: 10px;
  background-color: white;
  position: relative;
}

.heading {
  text-align: center;
  font-size: 25px;
  margin-bottom: 50px;
  font-weight: 700;
}

.selects {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0px 4px 46px rgba(50, 130, 207, 0.06);
  border-radius: 10px;
}

.one_select {
  width: 50%;
}

.thumblers {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.one_thumbler {
  margin-right: 100px;
}

.colors_section {
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
}

.color_picker {
  width: 184px;
}

.checkers {
  margin-top: 10px;
  margin-left: 180px;
}

.btn_container {
  width: calc(100% - 85px);
  margin: 80px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 50px;
  border-radius: 1000px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  transition: all .2s ease-in-out;
}

.btn_ok {
  background-color: var(--hardBlue);
  color: #fff;
}

.btn_ok:hover {
  background-color: var(--hardBlueHovered);
}

.btn_cancel {
  color: var(--hardBlue);
}

.btn_cancel:hover {
  color: var(--hardBlueHovered);
}

.times_close {
  position: absolute;
  margin: 0;
  top: 10px;
  right: 20px;
  color: var(--hardBlue);
  font-size: 50px;
  line-height: 50px;
  transition: all .2s ease-in-out;
  cursor: pointer;
  user-select: none;
}

.times_close:hover {
  color: var(--hardBlueHovered);
}
