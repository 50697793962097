.personal_area_container{
    font-size: 15px;
    padding: 30px 50px 120px 50px;
}

.heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

.heading_name{
    display: flex;
    align-items: center;
}

.heading_name h3{
    font-size: 25px;
}

.heading_add_button{
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding: 15px;
    height: 45px;
    background-color: var(--hardBlue);
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
    transition: all .2s ease-in-out;
}

.heading_add_button:hover{
    background-color: #107ce1;
}

.button_plus, .button_text{
    color: white;
    font-size: 14px;
}

.button_plus{
    font-size: 20px;
    margin-right: 6px;
}

.heading_filters{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search{
    max-width: 100px;
}

.sort_by{
    margin-left: 60px;
}

.trash_button{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 15px;
    left: 10%;
    right: 10%;
    height: 60px;
    background-color: var(--hardBlue);
    border-radius: 31px;
    z-index: 10000;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease-in-out;
}

.trash_button:hover{
    background-color: var(--hardBlueHovered);
}
