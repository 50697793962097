.remember_me {
    display: flex;
    align-items: center;
    color: var(--hardGray);
    float: left;
    font-size: 18px;
}

.slider_text {
    margin-left: 10px;
}
