.heading{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading h1{
  margin: 0 0 0 20px;
  font-size: 25px;
  font-weight: 700;
}

.filters{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search{
  width: 150px;
  margin-right: 50px;
}
