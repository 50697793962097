.prompt_container{
  padding: 68px;
  width: 630px;
  background-color: #fff;
  border-radius: 35px;
}

.prompt_heading{
  margin: 0 0 25px 0;
  text-align: center;
  font-size: 25px;
}

.prompt_subtitle{
  width: 70%;
  margin: 0 auto 50px auto;
  font-size: 18px;
  font-weight: 200;
  text-align: center;
}

.count_section{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 25px;
}

.subhead{
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.service{
  max-width: 48%;
}

.count{
  min-width: 48%;
}

.service_icon{
  display: flex;
  align-items: center;
}

.service_icon_img{
  width: 70px;
  margin-right: 10px;
}

.count_changer_container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 210px;
}

.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: #B7D5F5;
  font-size: 30px;
  cursor: pointer;
  user-select: none;
  transition: all .2s ease-in-out;
}

.btn:hover{
  background-color: #9ec7f2;
}

.input{
  width: 95px;
  height: 53px;
  border: 2px solid #BACDE0;
  border-radius: 7px;
  font-size: 17px;
  text-align: center;
}

.buttons_section{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button{
  width: 48%;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  transition: all .2s ease-in-out;
}

.send_button{
  background-color: var(--hardBlue);
  color: white;
}

.send_button:hover{
  background-color: var(--hardBlueHovered);
}

.cancel_button{
  background-color: var(--lightBlue);
  color: var(--hardBlue);
}

.cancel_button:hover{
  background-color: var(--lightBlueHovered);
}

.success_animation{
  margin: 35px auto;
  width: 100px;
}

.textarea{
  resize: none;
  width: 100%;
  padding: 16px 20px;
  margin-bottom: 30px;
  border: 2px solid #BACDE0;
  border-radius: 13px;
  font-size: 15px;
  line-height: 19px;
  transition: all .2s ease-in-out;
}

.textarea:focus{
  outline: none;
  border: 2px solid #92a5b8;
}

.textarea::-webkit-input-placeholder {
  color: rgba(128, 137, 154, 0.5);
}

.textarea:-moz-placeholder {
  color: rgba(128, 137, 154, 0.5);  
}

.textarea::-moz-placeholder {
  color: rgba(128, 137, 154, 0.5);  
}

.textarea:-ms-input-placeholder {
  color: rgba(128, 137, 154, 0.5);  
}

.textarea::placeholder {
  color: rgba(128, 137, 154, 0.5);  
}
